import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';

import Seo from '../framework/seo';

const NotFound = ({ uri }) => (
	<>
		<Seo title="404 - Page not found" />
		<Alert variant="danger">
			<Alert.Heading>404 - Page not found</Alert.Heading>
			<p>{uri}</p>
		</Alert>
	</>
);

NotFound.propTypes = {
	uri: PropTypes.string,
};

export default NotFound;
