import { Location, Router as ReachRouter } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';

const Router = ({children}) => (
	<Location>
		{({ location }) => (
			<ReachRouter location={location}>{children}</ReachRouter>
		)}
	</Location>
);

Router.propTypes = {
	children: PropTypes.node,
};

export default Router;
