import PropTypes from 'prop-types';
import React from 'react';

import useRequest from '../../hooks/use_request';
import authentication from '../../libs/authentication';
import Signin from '../dashboard/signin';
import Suspense from '../framework/suspense';

const Authentication = ({ children }) => {
	return (
		<Suspense tags={{ component: 'Authentication' }}>
			<Request>{children}</Request>
		</Suspense>
	);
};

const Request = ({ children }) => {
	const { data } = useRequest(
		authentication.isSignedIn() ? 'authentication.refresh' : null
	);

	if (!authentication.isSignedIn() || data?.status == 'error') {
		return <Signin />;
	}

	return children;
};

Authentication.propTypes = {
	children: PropTypes.object,
};

Request.propTypes = {
	children: PropTypes.object,
};

export default Authentication;
