import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import useRunOnce from '../../hooks/use_run_once';
import Loading from './loading';

const Initialize = ({ children }) => {
	const [loading, setLoading] = useState(true);

	useRunOnce({
		mount: () => {
			setLoading(false);
		},
	});

	if (loading) {
		return (
			<Container className="pt-5 text-center" fluid>
				<Loading size="lg" />
			</Container>
		);
	}

	return children;
};

Initialize.propTypes = {
	children: PropTypes.object,
};

export default Initialize;
