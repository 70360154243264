import PropTypes from 'prop-types';
import React from 'react';

import Authentication from '../components/authentication';
import Framework from '../components/framework';
import Initialize from '../components/framework/initialize';
import Seo from '../components/framework/seo';
import Suspense from '../components/framework/suspense';
import Router from '../components/route';
import NotFound from '../components/route/not_found';
import { AppProvider } from '../context/app';
import useTranslation from '../hooks/use_translation';

const Index = React.lazy(() => import('../screens/index'));
const ResultPrint = React.lazy(() => import('../screens/result/print'));

const App = ({ location }) => {
	const { t } = useTranslation('index');

	return (
		<>
			<Seo title={t('title')} />
			<Initialize>
				<Authentication location={location}>
					<AppProvider>
						<Framework>
							<Suspense
								fallback={<></>}
								loadingSize="full"
								tags={{ component: 'App' }}
							>
								<Router>
									<ResultPrint path="/result/:analysis_id/print" />
									<Index path="/*internalPath" />
									<NotFound default />
								</Router>
							</Suspense>
						</Framework>
					</AppProvider>
				</Authentication>
			</Initialize>
		</>
	);
};

App.propTypes = {
	location: PropTypes.object,
};

export default App;
