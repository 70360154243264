import { useLocation } from '@reach/router';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { createContext, useEffect, useMemo } from 'react';

import Suspense from '../components/framework/suspense';
import usePreview from '../hooks/use_preview';
import useRequest from '../hooks/use_request';

const default_values = {
	refreshUser: () => {},
	user: {},
};

export const AppContext = createContext(default_values);

export const AppProvider = ({ children }) => {
	return (
		<Suspense tags={{ component: 'AppProvider' }}>
			<Request>{children}</Request>
		</Suspense>
	);
};

const Request = ({ children }) => {
	const { setSetting } = usePreview();
	const { data: user, mutate: refreshUser } = useRequest('user.get');
	const location = useLocation();
	const search = queryString.parse(location?.search);

	useEffect(() => {
		if (user?.data?.user_id) {
			Sentry?.setUser({ id: user.data.user_id });
		}
		if (user?.data?.language_id) {
			Sentry?.setExtra('language_id', user.data.language_id);
		}
	}, [user]);

	useEffect(() => {
		if (search?.preview && search?.preview?.indexOf(':') !== -1) {
			const [previewKey, previewValue] = search.preview.split(':');

			if (!previewValue) {
				setSetting(previewKey, null);
			} else {
				setSetting(previewKey, previewValue);
			}
		}
	}, [search, setSetting]);

	const value = useMemo(() => {
		return { refreshUser, user: user?.data };
	}, [refreshUser, user]);

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Request.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
