import { useLocation } from '@reach/router';
import queryString from 'query-string';
import React from 'react';
import { Alert, Container, Image } from 'react-bootstrap';

import useTranslation from '../../hooks/use_translation';
import bloodLines from '../../images/assets/blood-lines.svg';
import bgPinkBlur from '../../images/blurs/pink-blur.svg';
import bgSunBlur from '../../images/blurs/sun-blur.svg';
import logo from '../../images/framework/logo.svg';
import Authenticate from '../authentication/authenticate';
import Language from '../framework/language';
import Link from '../framework/link';
import PartnerBranding from '../framework/partner-branding';
import Suspense from '../framework/suspense';
import HelpTag from '../help/tag';

const Signin = () => {
	const location = useLocation();
	const hash = queryString.parse(location?.hash);
	const search = queryString.parse(location?.search);
	const { t } = useTranslation('component_dashboard_signin');

	return (
		<Container className="signin" fluid>
			<div className="signin-wrapper pb-6 pb-lg-0 d-flex align-items-center justify-content-center mx-auto">
				<Image alt="sun blur" className="bg-sun pulsing2" src={bgSunBlur} />
				<Image alt="lines" className="element-blood-lines" src={bloodLines} />
				<Image
					alt="pink blur"
					className="bg-pink-blur pulsing3"
					src={bgPinkBlur}
				/>
				<div className="d-flex flex-column mt-5 mt-lg-0">
					<div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
						<div className="flex-column mt-lg-0">
							<Link to="/">
								<Image
									className="img-fluid signin-left-sidebar-brand mb-5 mb-lg-0"
									src={logo}
								/>
							</Link>
						</div>
						<div className="d-flex flex-column flex-grow-1">
							<div className="mx-auto mb-lg-5 mb-3 text-center">
								<PartnerBranding logo={logo} />
							</div>

							<Suspense tags={{ component: 'Signin' }}>
								<h1 className="h3 text-center">
									{t('t1')}
									<HelpTag className="p-2" id="signin_signup" />
								</h1>
								{/*
								<Alert className="bg-danger text-white" variant="danger">
									{
										'Just nu har vi problem med BankID, vänligen logga in via mail eller Freja eID. Vi är medvetna om problemet och arbetar för att lösa det så snart som möjligt, tack för din förståelse.'
									}
								</Alert>
								*/}
								<Authenticate
									method={hash?.method}
									redirect_url={search?.redirect}
									sid={hash?.sid}
								/>
							</Suspense>
						</div>

						<div className="flex-column">
							<Language />
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Signin;
