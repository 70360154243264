import useLocalStorageState from 'use-local-storage-state';

function usePreview() {
	const [settings, setSettings] = useLocalStorageState('preview', {});

	const getSetting = (key) => {
		if (!settings || !settings[key]) return null;
		return settings[key];
	};
	const setSetting = (key, value) => {
		setSettings((current) => {
			if (!current) current = {};

			if (!value) delete current[key];
			else current[key] = value;

			return current;
		});
	};

	return { getSetting, setSetting };
}

export default usePreview;
